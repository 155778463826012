import { ReactChild, ReactChildren } from 'react';

type ClickableWrapperProps = {
    children: ReactChild | ReactChild[] | ReactChildren;
    className?: string;
    href: string;
    target?: string;
};

export function ClickableWrapper({
  children,
  className,
  href,
  target,
}: ClickableWrapperProps) {
  return (
    <a
      className={className}
      href={href}
      target={target}
    >
      {children}
    </a>
  );
}

ClickableWrapper.defaultProps = {
  className: null,
  target: '_self',
};
