import Link from 'next/link';

type Props = {
    logo: { alt?: string; url?: string; }
};

export const LogoImage = ({ logo }: Props) => (
  <Link href='/' legacyBehavior>
    <a href='/'>
      <img
        alt={logo.alt}
        className='h-12 mx-auto md:m-0'
        src={logo.url}
      />
    </a>
  </Link>
);
