import { ReactElement } from 'react';

declare global {
    interface Window {
        adsbygoogle: any;
    }
}

type GoogleAdWrapperProps = {
    children: ReactElement;
};

export function GoogleAdSenseWrapper({ children }: GoogleAdWrapperProps) {
  return (
    <div className='w-full'>
      {children}
    </div>
  );
}
