import { getRedirectUrl, redirectUrl } from '@lib';
import { ReactElement } from 'react';
import { ProductType } from '@types';
import { StaticCardHeader } from './static-card-header';
import { StaticCardLayout } from './static-card-layout';
import { StaticCardTitleStars } from './static-card-title-stars';

type StaticWidgetProps = {
    source: string;
    items: ProductType[];
    title: string;
    renderCta: (url: string) => ReactElement;
    onItemClick?: (url: string) => void;
    renderBeforeList?: ReactElement;
};

export function StaticCard({
  items, title, onItemClick, source, renderCta, renderBeforeList,
}: StaticWidgetProps) {
  const handleOnClick = (id: string) => () => {
    if (onItemClick) onItemClick(getRedirectUrl(id, source));
    else redirectUrl(id, source);
  };

  return (
    <StaticCardLayout>
      <StaticCardHeader title={title}>{ renderBeforeList }</StaticCardHeader>
      <span role='menubar'>
        {
          items.map(({
            id, name, rating,
          }, index) => (
            <section
              role='menuitem'
              onKeyDown={(e) => e.preventDefault()}
              key={id}
              tabIndex={index}
              className={`flex justify-between items-center px-4 py-2 gap-x-2 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}
            >
              <StaticCardTitleStars name={name} rating={rating} index={index} onClick={handleOnClick(id)} />
              <div>
                {renderCta(getRedirectUrl(id, source))}
              </div>

            </section>
          ))
        }
      </span>
    </StaticCardLayout>
  );
}

StaticCard.defaultProps = {
  onItemClick: null,
  renderBeforeList: null,
};
