type RankingBadgeProps = {
    content: string;
};

export function RankingBadge({ content }: RankingBadgeProps) {
  return (
    <div className='h-8 w-8 rounded-full text-sm bg-gradient-to-b from-gray-100 to-gray-300 text-gray-600 flex justify-center items-center'>
      {content}
    </div>
  );
}
