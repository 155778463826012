import { ReactChildren, ReactElement } from 'react';

type Props = {
 title: string,
 children?: ReactChildren | JSX.Element[] | ReactElement,
};

export const StaticCardHeader = ({ title, children }: Props) => (
  <div>
    <div className='text-gray-700 p-3 text-lg uppercase font-semibold bg-gray-200'>
      {title}
    </div>
    { children }
  </div>
);

StaticCardHeader.defaultProps = {
  children: undefined,
};
