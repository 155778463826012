import { SearchIcon } from '@heroicons/react/solid';
import { C411_SEARCH_ENDPOINT } from '@backend';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

type SearchInputProps = {
  initialValue?: string;
  renderCustomButton?: () => DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement>;
};

export const SearchInput = ({ initialValue, renderCustomButton }: SearchInputProps) => (
  <form action={C411_SEARCH_ENDPOINT}>
    <div className='relative flex w-full'>
      <input
        type='search'
        name='q'
        className='relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-r-0 rounded-r-none border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
        placeholder='Search'
        defaultValue={initialValue}
      />
      <div className='inline-block'>
        {
          renderCustomButton ? renderCustomButton() : (
            <button type='submit' className='bg-gray-50 shadow px-4 py-2 rounded-md border border-gray-100 text-gray-100'>
              <SearchIcon className='w-6 h-6' />
            </button>
          )
        }
      </div>
    </div>
  </form>
);

SearchInput.defaultProps = {
  initialValue: null,
  renderCustomButton: null,
};
