import { ElementType } from 'react';
import { HorizontalLine } from './horizontal-line';

type ListProps = {
    color: string;
    items: string[];
    title: string;
    Icon: ElementType
};

export function List({
  color,
  items,
  title,
  Icon,
}: ListProps) {
  return (
    <div className='space-y-2.5'>
      <div className='space-y-2'>
        <h2 className='font-medium title-font tracking-widest text-gray-900 text-sm text-left uppercase'>
          {title}
        </h2>
        <HorizontalLine color={color} />
      </div>

      <nav
        role='list'
        className='flex flex-col text-left -mb-1 space-y-2.5'
      >
        {
          items.map((item) => (
            <span
              key={item}
              role='listitem'
              className='flex items-start gap-x-3 text-gray-600 text-base'
            >
              <div>
                <Icon className={`
                    w-4 h-4 mt-1 
                    ${color ? `text-${color}-400` : 'text-gray-400'}
                  `}
                />
              </div>
              <span>{item}</span>
            </span>
          ))
        }
      </nav>
    </div>
  );
}
