import { ElementType } from 'react';

type GhostCtaProps = {
    href: string;
    label: string;
    target?: string;
    IconSuffix: ElementType;
};

export function GhostCta({
  label,
  href,
  target,
  IconSuffix,
}: GhostCtaProps) {
  return (
    <a
      href={href}
      target={target}
      className='w-full md:w-auto flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
    >
      {label}
      <IconSuffix className='ml-3 -mr-1 h-5 w-5 ' />
    </a>
  );
}

GhostCta.defaultProps = {
  target: '_self',
};
