import { imageBlurDataUrl } from '@components';
import Image from 'next/image';

type LogoProps = {
    alt: string;
    className?: string;
    src: string;
};

export function Logo({ alt, className, src }: LogoProps) {
  return (
    <div className={`relative w-full h-12 max-h-10 md:max-h-12 ${className}`}>
      <Image
        alt={alt}
        blurDataURL={imageBlurDataUrl(100, 100)}
        layout='fill'
        loader={() => src}
        objectFit='contain'
        placeholder='blur'
        quality={100}
        src={src}
      />
    </div>
  );
}

Logo.defaultProps = {
  className: '',
};
