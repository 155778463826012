import { ElementType } from 'react';

type SolidCtaProps = {
    animateLeftRight?: boolean;
    animateTopDown?: boolean;
    color?: string;
    href: string;
    label: string;
    target?: string;
    IconPrefix: ElementType;
};

export function SolidCta({
  animateLeftRight,
  animateTopDown,
  color,
  href,
  target,
  label,
  IconPrefix,
}: SolidCtaProps) {
  return (
    <a
      href={href}
      target={target}
      className={`
            w-full md:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white 
            ${color ? `bg-${color}-600 hover:bg-${color}-700` : 'bg-indigo-600 hover:bg-indigo-700'}
            ${animateLeftRight || animateTopDown ? 'group' : ''}
        `}
    >
      <IconPrefix
        className={`
            mr-3 -ml-1 h-5 w-5
            ${animateLeftRight || animateTopDown ? 'relative' : ''}
            ${animateLeftRight ? 'animate-left-right' : ''}
            ${animateTopDown ? 'animate-bounce' : ''}
          `}
      />
      {label}
    </a>
  );
}

SolidCta.defaultProps = {
  animateLeftRight: false,
  animateTopDown: false,
  color: 'indigo',
  target: '_self',
};
