import { ChevronRightIcon } from '@heroicons/react/solid';

type ProductFeaturesProps = {
    features: {
        title: string;
        content: string
    }[];
};

export function ProductFeatures({ features }: ProductFeaturesProps) {
  return (
    <ul>
      {
        features.map(({ title, content }) => (
          <li key={title} className='py-1'>
            <span className='flex items-start gap-x-2 text-gray-600 text-base'>

              <div>
                <ChevronRightIcon className='w-5 h-5 text-blue-500 mt-0.5 md:mt-1' />
              </div>

              <span className='text-base text-gray-700 lg:leading-relaxed tracking-wide'>
                <span className='font-semibold'>
                  {title}
                  {' '}
                  -
                  {' '}
                </span>
                {' '}
                <span>{content}</span>
              </span>

            </span>
          </li>
        ))
      }
    </ul>
  );
}
