type HorizontalLineProps = {
    color: string;
    width?: number | string;
};

export function HorizontalLine({ color, width }: HorizontalLineProps) {
  return (
    <div className='flex flex-col'>
      <div className='h-1 bg-gray-200 rounded overflow-hidden'>
        <div className={`
            w-${width} h-full
            ${color ? ` bg-${color}-500` : 'bg-blue-500'}
          `}
        />
      </div>
    </div>
  );
}

HorizontalLine.defaultProps = {
  width: 12,
};
