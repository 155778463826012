import { RankingBadge } from '@new-components';
import { StarsByRating } from '@components';

type Props = {
  name: string;
  rating: number;
  index: number;
  onClick: () => void;
};

export const StaticCardTitleStars = ({
  name, rating, index, onClick,
}: Props) => (
  <div className='flex items-center gap-x-3.5'>
    <RankingBadge content={`#${index + 1}`} />

    <div className='flex flex-col items-start'>
      <p className='text-gray-900 text-base md:text-lg font-semibold cursor-pointer' onKeyDown={() => {}} onClick={onClick}>{name}</p>
      <StarsByRating rating={rating} />
    </div>
  </div>
);
