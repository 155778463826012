import { ComponentProps } from 'react';

type BaseCtaProps = {
    href?: string;
    label: string;
    target?: string;
    IconSuffix: (props: ComponentProps<'svg'>) => JSX.Element;
};

export function BaseCta({
  href,
  label,
  target,
  IconSuffix,
}: BaseCtaProps) {
  return href
    ? (
      <a
        href={href}
        target={target}
        onClick={(e) => e.stopPropagation()}
      >
        <span className='text-indigo-600 text-sm inline-flex items-center gap-x-2'>
          <span>{label}</span>
          {' '}
          <IconSuffix className='w-4 h-4' />
        </span>
      </a>
    ) : (
      (
        <span className='text-indigo-600 text-sm inline-flex items-center gap-x-2'>
          <span>{label}</span>
          {' '}
          <IconSuffix className='w-4 h-4' />
        </span>
      )
    );
}

BaseCta.defaultProps = {
  href: null,
  target: null,
};
