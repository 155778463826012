import { ArrowRightIcon } from '@heroicons/react/solid';
import { CategoryType } from '@types';

type ListItemProps = {
    href: string;
    label: string;
};

function ListItem({ href, label }: ListItemProps) {
  return (
    <li>
      <div className='flex gap-x-2.5 items-center'>
        <ArrowRightIcon className='w-4 h-4 text-indigo-600 text-opacity-80' />
        {/* due to a current bug in the next.js router, no Link tag will be used here. */}
        <a href={href} className='text-base text-gray-500 hover:text-blue-600 capitalize'>
          {label}
        </a>
      </div>
    </li>
  );
}

type PopularCategoriesCardProps = {
    icon: string;
    name: string;
    categories: CategoryType[];
};

export function PopularCategoriesCard({ icon, name, categories }: PopularCategoriesCardProps) {
  // Sort the categories alphabetically by the title property
  categories.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <div className='-mt-5 md:-mt-6'>

      <div className='flex justify-between'>

        <h3 className='mt-1 text-2xl md:text-3xl lg:text-4xl font-semibold md:font-medium text-gray-900 tracking-tight' style={{ textTransform: 'capitalize' }}>
          {name}
        </h3>

        <div>
          <span className='inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg'>
            <span className='material-symbols-outlined'>{icon}</span>
          </span>
        </div>

      </div>

      <hr className='mt-4 border border-gray-200' />

      <nav className='mt-5'>

        <ul className='space-y-2'>
          {categories.map(({ name: categoryName, slug }) => (
            <ListItem
              key={categoryName}
              href={`/${slug}/`}
              label={categoryName}
            />
          ))}
        </ul>

      </nav>

    </div>
  );
}
