import { ReactChildren, ReactElement } from 'react';

type Props = {
  children : ReactChildren | JSX.Element[] | ReactElement,
};

export const StaticCardLayout = ({ children }: Props) => (
  <div className='mt-0 rounded-lg border-4 border-t-0 border-gray-200 shadow-2xl divide-y-2 divide-gray-100 overflow-hidden'>
    {children}
  </div>
);
