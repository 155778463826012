import Image from 'next/image';
import { imageBlurDataUrl, StarsByRating } from '@components';
import {
  ArrowLeftIcon, ArrowUpIcon, ChevronDownIcon, ChevronRightIcon, ChevronUpIcon,
} from '@heroicons/react/solid';
import { getRedirectUrl } from '@lib';

function CtaLink({ id, name, source }: { id: string, name: string, source: string }) {
  return (
    <a
      onClick={(e) => e.stopPropagation()}
      href={getRedirectUrl(id, source)}
      target='_blank'
      className='group uppercase w-full flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm lg:text-base font-medium rounded-md text-white border-b-4 text-white bg-blue-600 border-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
      rel='noreferrer'
    >
      <ArrowLeftIcon className='h-5 w-5 mr-4 relative animate-left-right hidden md:block' />
      <ArrowUpIcon className='h-5 w-5 mr-4 relative animate-bounce block md:hidden' />

      <span className='text-center'>
        VISIT
        <span className='hidden md:inline'>
          {' '}
          {name}
        </span>
      </span>
    </a>
  );
}

function Pros({ items }: { items: string[] }) {
  return (
    <ul className='text-left'>
      {items.map((item: string) => (
        <li className='py-0.5' key={item}>
          <span className='text-gray-600 text-sm'>
            <div className='flex gap-x-0.5'>
              <div className='w-1/12 inline'>
                <ChevronRightIcon className='w-5 h-5 col-span-1 text-grey-500' />
              </div>

              <span className='w-11/12 text-gray-700 lg:leading-relaxed tracking-wide word-wrap'>
                <span>{item}</span>
              </span>
            </div>
          </span>
        </li>
      ))}
    </ul>
  );
}

type FeatureProps = {
    title: string;
    content: string;
};

function Feature({ title, content }: FeatureProps) {
  return (
    <li className='py-0.5'>
      <span className='text-gray-600 text-sm'>
        <div className='flex gap-x-0.5'>
          <div className='w-1/12 inline'>
            <ChevronRightIcon className='w-5 h-5 col-span-1 text-grey-500' />
          </div>

          <span className='w-11/12 text-gray-700 lg:leading-relaxed tracking-wide word-wrap'>

            <span className='font-semibold'>{title}</span>
            {' '}
            -
            {' '}
            <span>{content}</span>
          </span>
        </div>
      </span>
    </li>
  );
}

type ProductProps = {
    id: string,
    emblem: string,
    features: { title: string; content: string; }[],
    heroImage: string,
    source: string,
    longDescription: string,
    name: string,
    pros: string[],
    rank: number,
    rating: number,
    totalProducts: number,
    open: boolean,
    setOpen: () => void;
};

export function Product({
  id,
  emblem,
  features,
  heroImage,
  source,
  longDescription,
  name,
  pros,
  rank,
  rating,
  totalProducts,
  open,
  setOpen,
}: ProductProps) {
  const handleViewMoreButtonClick = (e) => {
    e.stopPropagation();
    setOpen();
  };

  return (
    <div className='relative bg-white rounded-md shadow-md border-2 '>

      <div>
        <div className='grid grid-cols-8 md:grid-cols-12 items-center bg-gray-50'>
          <div className='col-span-8 md:col-span-4 p-4'>
            <div className='flex flex-col items-center space-y-1'>

              <div className='relative w-full h-14 rounded-md'>
                <Image
                  loader={() => emblem}
                  src={emblem}
                  alt={name}
                  layout='fill'
                  objectFit='contain'
                />
              </div>

              <div className='flex flex-col items-center space-y-0.5 flex md:hidden'>
                <p className='text-base md:text-sm text-gray-700 font-semibold'>
                  #
                  {rank}
                  <span className='hidden md:inline'>
                    {' '}
                    ranking out of
                    {' '}
                    {totalProducts}
                  </span>
                </p>
                <StarsByRating rating={rating} />
              </div>

            </div>
          </div>

          <div className='col-span-3 md:col-span-4 p-4 hidden md:inline'>
            <div className='flex flex-col items-center space-y-0.5'>
              <p className='text-base md:text-sm text-gray-700 font-semibold'>
                #
                {rank}
                <span className='hidden md:inline'>
                  {' '}
                  ranking out of
                  {' '}
                  {totalProducts}
                </span>
              </p>
              <StarsByRating rating={rating} />
            </div>
          </div>

          <hr className='col-span-8 inline md:hidden' />

          <div className='col-span-8 md:col-span-4 p-4'>
            <div className='flex flex-col items-center space-y-1'>
              <CtaLink id={id} source={source} name={name} />
            </div>
          </div>

        </div>

        <div className={`grid grid-cols-12 ${open ? 'grid' : 'hidden md:grid'}`}>
          <div className='col-span-12'>
            <hr />
          </div>
        </div>

        <div className={`grid grid-cols-1 md:grid-cols-12 divide-y md:divide-y-0 md:divide-x ${open ? 'grid' : 'hidden md:grid'}`}>
          <div className='md:col-span-4'>
            <h2 className='uppercase text-sm font-semibold text-gray-700 px-4 py-2'>
              DESCRIPTION
            </h2>
            <hr />
            <div className='px-2 lg:px-4 py-2'>
              <div className='flex flex-col items-center space-y-4'>
                <div className='relative w-40 h-24'>
                  <Image
                    alt={name}
                    blurDataURL={imageBlurDataUrl(100, 100)}
                    className='rounded-md'
                    layout='fill'
                    loader={() => heroImage}
                    objectFit='cover'
                    placeholder='blur'
                    quality={80}
                    src={heroImage}
                  />
                </div>
                <p className='text-gray-500 text-sm text-left'>
                  {longDescription.substring(0, 100).trim()}
                  ...
                </p>
              </div>
            </div>
          </div>
          <div className='md:col-span-4'>
            <h2 className='uppercase text-sm font-semibold text-gray-700 px-4 py-2'>
              FEATURES
            </h2>
            <hr />

            <div className='px-2 lg:px-4 py-2'>
              <ul className='text-left'>
                {
                  features.map(({ title, content }) => (
                    <Feature
                      key={title}
                      title={title}
                      content={content}
                    />
                  ))
                }
              </ul>
            </div>
          </div>
          <div className='md:col-span-4'>
            <h2 className='uppercase text-sm font-semibold text-gray-700 px-4 py-2'>
              Pros
            </h2>
            <hr />

            <div className='px-2 lg:px-4 py-2'>
              <Pros items={pros} />
            </div>
          </div>
        </div>
      </div>

      <div className='absolute inset-x-0 -bottom-[12px] z-10 block md:hidden'>
        <div className='flex justify-center w-full text-center'>
          <button
            type='button'
            onClick={handleViewMoreButtonClick}
            className='rounded-full bg-white border border-gray-500 text-gray-700'
          >
            {open ? <ChevronUpIcon className='h-5 w-5' /> : <ChevronDownIcon className='h-5 w-5' />}
          </button>
        </div>
      </div>

    </div>
  );
}
