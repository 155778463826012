import { SearchIcon } from '@heroicons/react/solid';
import { SearchInput } from './search-input';

export const CustomSearchInput = () => (
  <SearchInput
    renderCustomButton={() => (
      <button
        className='inline-block px-5 py-3 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded-r border-l-0 shadow-md hover:bg-grey-200 hover:shadow-lg focus:bg-gray-700  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out flex items-center'
        type='submit'
      >
        <SearchIcon className='w-6 h-6' />
      </button>
    )}
  />
);
