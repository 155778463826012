import { ChevronRightIcon, SearchIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import { APP_URL } from '@backend';
import { ClickableWrapper } from './clickable-wrapper';

export function RelatedSearch({ categoryName }: { categoryName: string }) {
  const router = useRouter();

  const searchTerms = [
    `cheap ${categoryName}`,
    `${categoryName} near me`,
    `best ${categoryName}`,
    `affordable ${categoryName}`,
    `${categoryName} reviews`,
  ];

  const queryParams = router.asPath.split('?').length > 0
    ? router.asPath.split('?')[1]
    : '';

  return (
    <div className='bg-gray-50 rounded-md shadow-md px-4 py-2'>
      <p className='text-sm text-gray-700 font-semibold'>
        Explore similar subjects
      </p>
      <div className='space-y-2 mt-2'>
        {
          searchTerms.map((searchTerm) => (
            <ClickableWrapper
              className='block'
              href={encodeURI(`${APP_URL}/search/?q=${searchTerm.toLowerCase()}${queryParams ? `&${queryParams}` : ''}`)}
            >
              <div
                key={searchTerm}
                className='flex justify-between p-2 bg-white border rounded-md text-blue-900'
              >
                <SearchIcon className='w-5 h-5' />
                <p className='capitalize'>
                  {searchTerm}
                </p>
                <ChevronRightIcon className='w-5 h-5' />
              </div>
            </ClickableWrapper>
          ))
        }
      </div>
    </div>
  );
}
